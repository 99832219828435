.SideBar {
    width: 300px;
    border: 1px solid var(--secondary-dark);
    background-color: var(--secondary-dark);
    z-index: 1;
    display: flex;
    flex-direction: column;
    
    .header {
        display: flex;
        align-items: center;
        padding: 15px;
        justify-content: space-between;
        color: #fff;
    }

    .list {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #ffffff28;
        padding: 20px 0;

        .link {
            all: unset;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: var(--secondary-smoke-blue);
            gap: 10px;
            cursor: pointer;

            &:hover {
                background-color: var(--button-shadow);
                color: var(--secondary-light-gray);
            }
        }

    }
}

