.app-input {
    padding: 9px;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    flex: 1;
}

.app-input-parent:focus {
    outline: 1px solid var(--primary-blue-dark);
    box-shadow: 0 0 0 1px var(--primary-blue-dark);
    border-color: var(--primary-blue-dark);
}

.react-select .select__control--is-focused {
    border-color: var(--primary-blue-dark);
    box-shadow: 0 0 0 1px var(--primary-blue-dark);
}

.select__control  {
    border: 0;
    border-width: 0 !important;
}

.right-addon {
    padding-right: 10px;
}

.left-addon {
    padding-left: 10px;
}

.app-input-parent, .UploadImage {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.app-input-control {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.app-input-control, .app-input-control > .react-select {
    flex: 1;
}


.label-input {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
}

.app-input-icon {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 10;
}

.app-input-icon.right-icon~input {
    padding-right: 28px;
}

.app-input-icon.right-icon~.react-select .select__control {
    padding-right: 26px;
}

.app-input-icon.left-icon~input {
    padding-left: 32px;
}

.app-input-icon.left-icon~.react-select .select__control {
    padding-left: 24px;
}

.left-icon {
    right: auto;
    left: 7px;
}

.label-input>label {
    font-size: 14px;
    min-height: 16px;
}

.app-button {
    padding: 7px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.app-button:active {
    background-color: rgb(163, 163, 163);
    border: 1px solid #000;
}

.app-input-parent.has-error  {
    border: 1px solid red;
}

.app-input-error-message,
.label-input.has-error>label,
.error-icon {
    color: red;
}

.UploadImage {
    min-width: 50px;
    min-height: 50px;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.UploadImage .app-input-control {
    align-items: center;
    justify-content: center;
}

.UploadImage .app-input-control span {
    font-size: 40px;
    border: 1px dashed #ccc;
    border-radius: 50%;
    padding: 15px;
}

.UploadImage.has-error .app-input-control {
    color: red;
}


.UploadImage .error-icon {
    display: none;
}