.auth-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #21252918;
    // background-image: url("./img/auth-bg.jpg");
    background-size: cover;
  }
  
  .auth-form {
    width: 300px;
    min-height: 450px;
    border: 1px solid #ccc;
    padding: 30px 20px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 12px 0px #cccccc85;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .auth-form .logo {
    max-width: 80%;
  }
  
  .auth-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .auth-form > h2 {
    margin: 0;
    font-size: 20px;
  }
  
  .sep-text {
    display: flex;
    align-items: center;
  }
  
  .sep-text-content {
    padding: 0 15px;
    font-size: 18px;
  }
  
  .sep-text-line {
    flex: 1;
    height: 1px;
    background-color: #ececec;
  }
  
  .auth-form-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }
  
  .auth-form-actions > small {
    text-align: center;
  }
  
  .auth-btn {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: black;
    border: 1px solid #000;
    border-radius: 3px;
    padding: 0 20px;
    cursor: pointer;
    white-space: nowrap;
    min-width: 80px;
  }
  
  .auth-btn:active {
    color: rgb(138, 138, 138);
    background-color: rgb(1, 8, 66);
  }
  
  .auth-SSO-btn {
    height: 50px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    max-width: 250px;
    margin: 0 auto;
    cursor: pointer;
  }
  
  .auth-SSO-btn:active {
    color: rgb(87, 87, 87);
    background-color: rgb(199, 199, 199);
  }
  
  .auth-SSO-btn > img {
    height: 30px;
    width: 30px;
    margin-right: 15px;
  }
  
  .auth-form a {
    text-decoration: none;
  }
  
  .success-alert {
    color: green;
  }
  
  .input-auth-elm {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .input-auth-elm > input {
    font-size: 14px;
    background: #f9f9f9;
    background: linear-gradient(180deg,#f9f9f9 0,#e5e5e570);
    border-color: #ced4da70;
    border-radius: 5px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f9f9f9",endColorstr="#e5e5e570",GradientType=0);
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input-auth-elm-has-error > label, .input-auth-elm-has-error > small{
  color: rgb(180, 7, 7);
}

.input-auth-elm-has-error > input{
  border-color: rgb(180, 7, 7);
}
