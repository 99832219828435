.autocomplete {
    margin: 0;
}
.autocomplete  > input {
    padding: 5px;
    display: block;
}

.autocomplete-list {
    max-width: 500px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1px;
    border: 1px solid #e1e1e1;
    position: absolute;
    min-height: 25px;
    min-width: 250px;
    z-index: 100;
    box-shadow: 3px 3px 8px 0px #dbdbdb;
}

.autocomplete-item {
    all: unset;
    padding: 5px;
    background-color: #fafafa;
}

.autocomplete-item.active {
    background-color: var(--primary-blue-dark);
    color: #fff;
}

.autocomplete-loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}