
:root {
    --primary-dark: #072341;
    --primary-blue: #0086c5;
    --primary-blue-dark: #0081c1;
    --primary-blue-hover: #0b8bc8;
    --primary-blue-disabled: #7fd0f6;
    --primary-blue-bright: #a0ddf9;
    --primary-blue-light: #00a1ed;
    --secondary-dark: #1c4065;
    --secondary-dark-blue: #26405d;
    --secondary-medium-blue: #4e657e;
    --secondary-smoke-blue: #8195a9;
    --secondary-medium-gray: #dfe4e9;
    --secondary-gray: #eaeff5;
    --secondary-gray50: #eaeff580;
    --secondary-smoke-gray: #ccd7df;
    --secondary-gray-dark: #ccd4dc;
    --secondary-gray-medium-dark: #ebf0f7;
    --secondary-light-gray: #ecf2f6;
    --secondary-red: #fc4846;
    --secondary-red-light: #fc484640;
    --secondary-red-dark: #e03520;
    --secondary-yellow: #fcad46;
    --secondary-yellow-light: #feead0;
    --secondary-yellow-dark: #fcad4633;
    --secondary-green: #12d28a;
    --secondary-green-dark: #228d3a;
    --secondary-green-dark-light: #b4e28c;
    --secondary-green-lighter: #c3f4e2;
    --secondary-green-light: #12d28a40;
    --medium-green-light: #96a040;
    --greenish-yellow: #eaf1ac;
    --secondary-purple: #640eff;
    --input-border: rgba(129, 149, 169, 0.4);
    --input-background: #061e37;
    --divider-color: #ccd4dc;
    --menu-item-background: #213454;
    --notification-background: #deeee1;
    --progress-bar-background: #f7fafc;
    --secondary-light-blue: #00a1ed;
    --dropdown-shadow: rgba(0, 0, 0, 0.15);
    --search-shadow: #07234126;
    --button-shadow: #00000029;
    --white: #fff;
    --black: #0000001a;
    --grey: #c7d7e4;
    --orange: #e67500;
}
