@import url('./shared/Theme/Style.scss');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--secondary-gray);
}

.AuthLayout {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.list-v {
  display: flex;
  flex-direction: column;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 10px;
}

.form-auth {
  min-width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f03f;
}

.form-auth > button {
  align-self: flex-start;
}

.form-auth > h1 {
  margin: 0;
}


.Layout {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}
